import React from 'react'

import App from '../src/App'
import getDefaultPreviewImage from '../src/getDefaultPreviewImage'

export default function Index(props) {
  return (
    <App {...props} />
  )
}

export async function getServerSideProps(context) {
  const previewImage = getDefaultPreviewImage(context)
  const intro = {
    title1: 'Breaking',
    title2: 'Bad',
    status: 'created',
    video: null,
  }
  const introAPIPath = '/api/breaking-bad-intros/-default'

  return {
    props: {
      previewImage,
      fallback: {
        [introAPIPath]: intro,
      },
    },
  }
}
